<template>
  <div>
    <p class="main-header-section" style="margin-bottom: 20px">Избранное</p>

    <b-overlay
      :show="is_loading"
      spinner-variant="primary"
      variant="transparent"
      style="flex-wrap: wrap; position: unset !important;"
      class="d-grid-cards-catalog"
    >
      <b-card-body
        v-if="Offers.items.length === 0 && !is_loading"
        class="d-flex flex-column p-0"
      >
        <b-card>
          <div class="d-flex flex-column justify-content-center pt-2 pb-2">
            <div class="nodata-emoji-icon ml-auto mr-auto"></div>
            <p
              class="mt-1 ml-auto mr-auto text-center"
              style="font-weight: 500; font-size: 18px; line-height: 22px"
            >
              Вы пока не добавили<br />
              предложения в Избранное
            </p>
            <center>
              <b-button
                variant="primary"
                @click="goToMain"
                style="width: 284px; border-radius: 8px"
              >
                Открыть все предложения
              </b-button>
            </center>
          </div>
        </b-card>
      </b-card-body>

      <offer-card-view
        v-else-if="!is_loading"
        v-for="(offer, index) in Offers.items"
        :key="index"
        :item="offer"
        style=""
      />
    </b-overlay>

    <div class="mt-2 btn-more-footer" v-if="Offers.has_more_pages && !is_loading">
      <center>
        <b-button
          v-if="!is_additional_loading"
          @click="loadMore"
          variant="primary-dark"
        >
          Показать еще
        </b-button>
        <b-button v-else variant="primary-dark" disabled>
          <b-spinner small type="grow"></b-spinner>
          Загрузка...
        </b-button>
      </center>
    </div>
  </div>
</template>

<script>
import OfferCardView from "@/components/OfferCard";

import Offers from "@/modules/offers/";

export default {
  data() {
    return {
      Offers,
      is_loading: true,
      is_additional_loading: false,
      filter: {
        category: null,
        company: null,
        action: null,
      },
    };
  },
  props: {},
  methods: {
    multiWidth(items) {
      if (items.length == 1) return { width: "100%" };
      else if (items.length == 2) return { width: "49%" };
      else return { width: "32%" };
    },
    loadMore() {},
    goToMain() {
      this.$router.push({
        name: "user-dashboard",
        params: {
          item: "all",
        },
      });
    },
  },
  components: {
    OfferCardView,
  },

  computed: {
    favorites() {
      return this.$user.self.favorites;
    },
  },

  watch: {},
  mounted() {
    Offers.list(true, { favorites: true }, () => {
      this.is_loading = false;
    });
  },
};
</script>


<style lang="scss">
</style>
