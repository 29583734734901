<template>
  <b-card no-body v-ripple style="cursor: pointer; margin-bottom: 0" @click="view">
    <b-card-body class="offer-card" style="padding: 0px">
      <showAt breakpoint="large">
        <desktop-view :item="item" :compact="compact">
          <template slot="action">
            <slot name="action"></slot>
          </template>
        </desktop-view>
      </showAt>
      <showAt breakpoint="medium">
        <tablet-view :item="item" :compact="compact">
          <template slot="action">
            <slot name="action"></slot>
          </template>
        </tablet-view>
      </showAt>
      <showAt breakpoint="small">
        <small-view :item="item" :compact="compact">
          <template slot="action">
            <slot name="action"></slot>
          </template>
        </small-view>
      </showAt>
    </b-card-body>
  </b-card>
</template>

<script>
import SmallView from "./views/small";
import TabletView from "./views/tablet";
import DesktopView from "./views/desktop";

export default {
  data() {
    return {};
  },
  props: {
    item: Object,
    compact: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    view() {
      this.$router.push({
        name: "user-offer-item",
        params: {
          item: this.item.id,
        },
      });
    },
  },
  components: {
    SmallView,
    TabletView,
    DesktopView,
  },
  watch: {},
  mounted() { },
};
</script>

<style lang="scss">
.offer-card {
  .offer-card-title {
    font-weight: 500;
    font-size: 18px;
    max-width: 300px;
  }

  .offer-card-body {
    padding: 20px;
  }

  .offer-card-info {
    margin-bottom: 46px;

    li {
      margin-right: 4px;
    }
  }
}
</style>