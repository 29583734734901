<template>
  <div class="d-flex flex-column justify-content-between">
    <div
      class="attachment"
      :style="{
        margin: '0px',
        width: '100%',
        '--thumb': `url(${item.medias[0].urls.original})`,
        '--placeholder': `url(${item.medias[0].urls.placeholder})`,
        borderRadius: '24px',
      }"
    >
      <div :style="mediaViewStyleLogoCard()" class="offer-card-logo"></div>
    </div>

    <div
      class="d-flex flex-column justify-content-between offer-card-body"
      style="width: 100%; height: 100%"
    >
      <div>
        <p class="offer-card-title" v-html="item.title"></p>
        <ul
          class="list-unstyled"
          style="margin-bottom: 43px; display: flex; flex-wrap: wrap; gap: 4px"
        >
          <li style="display: inline-block">
            <b-badge
              variant="secondary"
              style="
                border-radius: 50px;
                padding: 4px 8px 6px 8px;
                font-weight: 400;
              "
            >
              {{ item.has_category.name }}
            </b-badge>
          </li>
          <!-- <li
            style="display: inline-block"
            v-for="(city, index) in item.cities"
            :key="index"
          >
            <b-badge
              variant="secondary"
              style="
                border-radius: 50px;
                padding: 4px 8px 6px 8px;
                font-weight: 400;
              "
            >
              {{ city.name }}
            </b-badge>
          </li> -->
          <li v-if="item.cities.length == 0" style="display: inline-block">
            <b-badge
              variant="secondary"
              style="
                border-radius: 50px;
                padding: 4px 8px 6px 8px;
                font-weight: 400;
              "
            >
              Вся РФ
            </b-badge>
          </li>
        </ul>
      </div>

      <div class="d-flex justify-content-between">
        <div class="d-flex flex-column">
          <small class="text-muted">Доход</small>
          <span class="fs-24 font-weight-bolder">
            {{ item.goals && item.goals.length > 1 ? 'до ' : '' }}
            {{!item.is_percentage_income ? item.amount.formatMoney(" ", " ", " ")+' ₽' : item.amount+' %' }}
          </span>
        </div>
        <div class="d-flex flex-column" v-if="item.conformation_period">
          <small class="text-muted">Срок подтверждения</small>
          <span class="fs-24 text-nowrap"
            >до {{ item.conformation_period }}
            {{
              Utils.declOfNum(item.conformation_period, ["день", "дня", "дней"])
            }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Utils from "@/modules/utils/";

export default {
  data() {
    return {
      Utils,
    };
  },
  props: {
    item: Object,
  },
  methods: {
    mediaViewStyleLogoCard() {
      return {
        position: "absolute",
        height: "40px",
        width: "96px",
        marginLeft: "20px",
        backgroundImage: `url(${
          this.item.has_company.medias[0].thumb ||
          this.item.has_company.medias[0].urls.thumb
        })`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        borderRadius: "12px",
        border: "1px solid #CBD2E0",
      };
    },
  },
  components: {},
  watch: {},

  computed: {},
  mounted() {},
};
</script>
